import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Paragraph from "../components/Paragraph"
import Seo from "../components/seo"
import Title from "../components/Title"
import rehypeReact from "rehype-react"
import { v4 } from "uuid"
import { motion } from "framer-motion"

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {"p": Paragraph},
}).Compiler

const Video = ({videoUrl, videoTitle, className}) => {

  const videoID = videoUrl.split("=")

  return( 
    <div>
      <h3 className="text-xl">{videoTitle}</h3>
      <iframe className={className} src={"https://www.youtube.com/embed/" + videoID[1]} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  )
}

const VideoPage = ({data}) => {
  const {markdownRemark: markdown} = data
  
  return <Layout>
    <Seo title={markdown.frontmatter.title} />
    
    <Title className="text-center">
      {markdown.frontmatter.title}
    </Title>

    <div>
    {
        markdown.frontmatter.videos.map((item, index) => {

                return <motion.div 
                          className="text-center mb-8 last:mb-0"
                          key={v4()} 
                          initial={{translateY: "50px"}} 
                          whileInView={{translateY: 0}}
                          transition={{duration: 0.5}}
                          >
                  <Video key={index} videoUrl={item.video} videoTitle={item.title} className="my-4 lg:my-0 lg:w-full flex flex-col flex-grow lg:h-128"/>
                </motion.div>
            }
        )
    }
    </div>
    
    {renderAst(markdown?.htmlAst)}
    
  </Layout>
}

export const query = graphql`
query {
  markdownRemark(fields: { slug: { eq: "/video/" } }) {
    htmlAst
    frontmatter {
      title
      videos {
        video
        title
      }
    }
  }
}
`

export default VideoPage
